/* eslint-disable no-sequences */
import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../assets/close.svg';

const Wrapper = styled.div`
  z-index: 99999;
  opacity: ${props => props.visible ? 1 : 0};
  pointer-events: ${props => props.visible ? 'auto' : 'none'};

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0,0,0,0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const Content = styled.div`
  max-width: 480px;
  width: 100%;
  background-color: #161616;
  border-radius: 8px;
  border: 1px solid #5A5A5A;
  padding: 34px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  font-size: 1.4rem;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const Description = styled.p`
  color: #6F6E6E;
  line-height: 1.4rem;
  font-weight: 500;
`;

const CloseButton = styled.a`
  padding-top: 20px;
  display: flex;
  align-items: center;
  color: #40C384;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
`;

const Modal = ({ visible = false, onDismiss, title, description }) => {
  const element = useRef(document.createElement('div'));

  useEffect(() => {
    const modalRoot = document.getElementById('modal-root');

    modalRoot.appendChild(element.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => modalRoot.removeChild(element.current);
  })

  return ReactDOM.createPortal(
    (
      <Wrapper visible={visible}>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <CloseButton href="#" onClick={e => (e.preventDefault(), onDismiss())}>
            Close
            <CloseIcon style={{ paddingLeft: 8 }} />
          </CloseButton>
        </Content>
      </Wrapper>
    ), element.current
  );
};

export default Modal;
