import React from 'react';
import styled from 'styled-components';

import HeatImageSource from '../assets/cow-tree.png';

const Wrapper = styled.div`
  background-color: #153E2F;
  border: 2px solid #2BB97D;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  padding: 20px 16px;
  display: flex;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.05em;
  color: #fff;
  margin: 0;
  padding: 0 0 4px 0;
`;

const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.2rem;
  letter-spacing: 0.05em;
  color: #60CF54;
  margin: 0;
  padding: 0;
`;

const HeatImage = styled.img`
  max-width: 100%;
  padding: 0;
  margin: 0;
`;

const HeatPanel = () => (
  <Wrapper>
    <Content>
      <TitleWrapper>
        <Title>Prepay to receive preferential pricing and secure priority deployment in your area</Title>
      </TitleWrapper>
    </Content>
    <HeatImage src={HeatImageSource} />
  </Wrapper>
);

export default HeatPanel;
