import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.p`
  color: ${props => props.failed ? '#C82020' : '#ffffff'};
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledInput = styled.input`
  border: 1px solid ${props => props.failed ? '#C82020' : '#3D3D3D'};
  background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), #1F1F1F;
  color: #9C9C9C;
  padding: 16px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
`;

const StyledTextarea = styled.textarea`
  border: 1px solid ${props => props.failed ? '#C82020' : '#3D3D3D'};
  background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), #1F1F1F;
  color: #9C9C9C;
  padding: 16px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  resize: none;
`;

const Input = ({ label, textarea = false, value, ...rest }) => {
  const [field, meta] = useField({ value, ...rest });

  const failed = meta.touched && meta.error != null;

  return (
    <Wrapper>
      <Label failed={failed}>{label}</Label>
      {textarea ? <StyledTextarea failed={failed} {...field} {...rest}>{value}</StyledTextarea> : <StyledInput value={value} failed={failed} {...field} {...rest} />}
    </Wrapper>
  );
}

export default Input;
