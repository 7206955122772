import React from 'react';
import { StripeProvider } from 'react-stripe-elements';

import MainScreen from './screens/main.screen';

const Application = () => (
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
    <MainScreen />
  </StripeProvider>
);

export default Application;
