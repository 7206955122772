import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Tick } from '../assets/tick.svg';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 24px;
`;

const TickWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.p`
  color: #FEFEFE;
  font-weight: bold;
  font-size: 4rem;
  margin: 20px 0;
`;

const Description = styled.p`
  color: #6F6E6E;
  font-weight: 500;
  line-height: 1.4rem;
  padding: 0 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
`;  

const BackButton = styled.button` 
  text-transform: uppercase;
  color: #000;
  padding: 16px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #284987 -35.71%, #2B86B9 100%);
  border-radius: 8px;
  font-weight: 800;
  font-size: 1rem;
  text-decoration: none;
`;

const FacebookButton = styled(BackButton)`
  color: #fff; 
  background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #284987 -35.71%, #2B86B9 100%);
  margin-bottom: 16px;
`;

const SuccessPanel = () => (
  <Wrapper>
    <TickWrapper>
      <Tick />
    </TickWrapper>
    <Title>Thanks</Title>
    <Description>Your payment has been successful, Halter will be in touch to work out requirements for your farm.</Description>
    <ButtonWrapper>
      <BackButton onClick={() => window.location.reload()}>Start Over</BackButton>
    </ButtonWrapper>
  </Wrapper>
);

export default SuccessPanel;
