import React, { useState } from 'react';
import styled from 'styled-components';

import { useField } from 'formik';
import Modal from './modal';

const Wrapper = styled.div`

`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxText = styled.p`
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: ${props => props.hasError ? '#C82020' : '#9C9C9C'};
  padding-left: 12px;
`;

const Checkbox = styled.div`
  width: 26px;
  height: 26px;
  border: 1px solid #3d3d3d;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), #1F1F1F;
`;

const CheckboxInner = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: linear-gradient(180deg, #FFFFFF -153.12%, #40C484 100%);
`;

const WhyText = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  padding-left: 42px;
  margin: 0;
  margin-top: -4px;
  display: flex;
`;

const Link = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover { text-decoration: underline; }
`;

const NotWaikatoCheckbox = () => {
  const [whyModalVisible, setWhyModalVisible] = useState(false);

  const [field, meta, helpers] = useField('notWaikato');

  return (
    <>
      <Wrapper>
        <CheckboxWrapper onClick={() => (helpers.setTouched(true), helpers.setValue(!field.value))}>
          <Checkbox>{field.value && <CheckboxInner />}</Checkbox>
          <CheckboxText hasError={meta.error != null}>Confirm address is outside the Waikato</CheckboxText>
        </CheckboxWrapper>
        <WhyText onClick={() => setWhyModalVisible(true)}>Why is this?</WhyText>
      </Wrapper>
      <Modal
        visible={whyModalVisible}
        onDismiss={() => setWhyModalVisible(false)}
        title="Located in the Waikato?"
        description={(
          <>
            <p>Farmers located in the Waikato are able to sign contracts and receive earlier deployment.</p>
            <p>Please email <Link href="mailto:contact@halter.co.nz">contact@halter.co.nz</Link> if you are located in the Waikato and interested in receiving Halter.</p>
          </>
        )}
      />
    </>
  );
}

export default NotWaikatoCheckbox;
