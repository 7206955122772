/* eslint-disable no-sequences */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';

import { ReactComponent as Logo } from '../assets/logo.svg';

import spinner from '../assets/spinner.png';

import Input from './input';
import Select from './select';
import StripeInput from './stripe-input';
import Axios from 'axios';
import InfoPanel from './info-panel';
import SuccessPanel from './success-panel';

import { device } from '../constants/media';
import Modal from './modal';
import NotWaikatoCheckbox from './not-waikato-checkbox';

const Wrapper = styled.aside`
  height: 100vh;
  border-left: 1px solid #363636;
  display: flex;
  flex-direction: column;
  
  @media ${device.laptop} {
    height: auto;
    width: 430px;
  }
`;

const Sections = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const Section = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: #000;
`;

const LogoSection = styled.div`
  display: none;
  flex-direction: column;

  @media ${device.laptop} {
    display: flex;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: center;
  border-bottom: 1px solid #2D2D2D; 
`;

const OrderSection = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #2D2D2D; 
  border-bottom: none;
  padding: 24px;
`;

const OrderButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #28875F -35.71%, #2BB97D 100%);
  border-radius: 4px;
  font-weight: bold;
  color: #ffffff;
  padding: 18px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
`;

const OrderTerms = styled.p`
  color: #9D9D9D;
  font-size: 0.8rem;
`;

const OrderLink = styled.a`
  color: #fff;
`;

const FormWrapper = styled.div`
  margin: -8px;
  padding: 16px 24px;
`;

const Item = styled.div`
  padding: 8px;
`;

const FormSection = styled(Section)`
  opacity: ${props => props.visible ? 1 : 0 };
  pointer-events: ${props => props.visible ? 'auto' : 'none' };
  transform: translateY(${props => props.visible ? '0' : '50px'});
  transition: opacity 0.5s, transform 0.5s;
`;

const DueTodayText = styled.p`
  color: #0C9D7D;
  font-weight: bold;
`;

const DueTodayPrice = styled.span`
  color: #fff;
`;

const RefundText = styled.p`
  color: #6f6e6e;
  font-weight: 600;
  line-height: 1.4rem;
  margin-top: 0;
`;

const Spinner = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  animation:spin 1s linear infinite;

  @keyframes spin { 100% { transform:rotate(-360deg); } }
`;

const initialValues = {
  fullName: '',
  emailAddress: '',
  phoneNumber: '',
  companyName: '',
  farmAddress: '',
  region: '',
  postCode: '',
  herdSize: '',
}

const Schema = Yup.object().shape({
  fullName: Yup.string().min(2).required(),
  emailAddress: Yup.string().email().required(),
  phoneNumber: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Phone number is not valid').required(),
  companyName: Yup.string().required(),
  farmAddress: Yup.string().required(),
  region: Yup.string().required(),
  herdSize: Yup.string().matches(/^[0-9]+$/).required(),
  postCode: Yup.string().matches(/^[0-9]+$/).required(),
});

const getButtonText = (index, isSubmitting) => {
  if (isSubmitting) return 'Processing...';

  switch (index) {
    case 0:
      return 'Prepay';
    case 1: 
      return 'Submit Details';
    case 2:
      return 'Pay';
    default:
      return '';
  }
}

const Sidebar = ({ stripe }) => {
  const [unknownErrorVisible, setUnknownErrorVisible] = useState(false);

  const [sectionIndex, setSectionIndex] = useState(1);

  const [stripeErrors, setStripeErrors] = useState({ cardNumber: false, expiration: false, cvc: false });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            //const { data: { clientSecret } } = await Axios.post('https://ef930yyw90.execute-api.ap-southeast-2.amazonaws.com/dev/deposits/intent');
            const { data: { clientSecret } } = await Axios.post(process.env.REACT_APP_API_URL);

            const result = await stripe.handleCardPayment(clientSecret, {
              payment_method_data: {
                metadata: { herd_size: values.herdSize, company_name: values.companyName, region: values.region },
                billing_details: { 
                  name: values.fullName, 
                  email: values.emailAddress, 
                  phone: values.phoneNumber, 
                  address: {
                    line1: values.farmAddress,
                    line2: `Company: ${values.companyName} Herd: ${values.herdSize}`,
                    state: values.region,
                    postal_code: values.postCode
                  }
                }
              }
            });

            if (result.error) {
              setStripeErrors({ cardNumber: true, expiration: true, cvc: true });
            } else {
              setSectionIndex(3);
            }
          } catch {
            setUnknownErrorVisible(true);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, handleSubmit, validateForm, setTouched, isSubmitting }) => (
          <Wrapper id="sidebar">
            <LogoSection>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
            </LogoSection>
            <Sections>
              {/* <Section>
                <InfoPanel />
              </Section> */}
              <FormSection visible={sectionIndex >= 1}>
                <FormWrapper>
                  <Item>
                    <Input name="fullName" label="Full name" />
                  </Item>
                  <Item>
                    <Input name="emailAddress" label="Email address" />
                  </Item>
                  <Item>
                    <Input name="phoneNumber" label="Phone number" />
                  </Item>
                  <Item>
                    <Input name="companyName" label="Company name" />
                  </Item>
                  <Item>
                    <Input name="farmAddress" textarea label="Farm address" rows={3} />
                  </Item>
                  <Item>
                    <Select name="region" label="Region">
                      <option value="">Select a Region</option>
                      <option>Northland</option>
                      <option>Auckland</option>
                      <option>Waikato</option>
                      <option>Bay of Plenty</option>
                      <option>Gisborne</option>
                      <option>Hawke's Bay</option>
                      <option>Taranaki</option>
                      <option>Manawatū-Whanganui</option>
                      <option>Wellington</option>
                      <option>Tasman</option>
                      <option>Nelson</option>
                      <option>Marlborough</option>
                      <option>West Coast</option>
                      <option>Canterbury</option>
                      <option>Otago</option>
                      <option>Southland</option>
                    </Select>
                  </Item>
                  <Item>
                    <Input name="postCode" label="Post code" />
                  </Item>
                  <Item>
                    <Input name="herdSize" label="Herd size" />
                  </Item>
                </FormWrapper>
              </FormSection>
              <FormSection visible={sectionIndex >= 2}>
                <FormWrapper>
                  <Item>
                    <StripeInput label="Card number" name="cardNumber" failed={stripeErrors.cardNumber}>
                      <CardNumberElement onChange={e => setStripeErrors(c => ({ ...c, cardNumber: e.error != null }))} style={{ base: { color: '#9C9C9C' }, invalid: { color: '#9C9C9C' } }} />
                    </StripeInput>
                  </Item>
                  <Item>
                    <StripeInput name="expiration" label="Expiration" failed={stripeErrors.expiration}>
                      <CardExpiryElement onChange={e => setStripeErrors(c => ({ ...c, expiration: e.error != null }))} style={{ base: { color: '#9C9C9C' }, invalid: { color: '#9C9C9C' } }} />
                    </StripeInput>
                  </Item>
                  <Item>
                    <StripeInput name="cvc" label="CVC" failed={stripeErrors.expiration}>
                      <CardCVCElement onChange={e => setStripeErrors(c => ({ ...c, cvc: e.error != null }))} style={{ base: { color: '#9C9C9C' }, invalid: { color: '#9C9C9C' } }} />
                    </StripeInput>
                  </Item>
                </FormWrapper>
              </FormSection>
              <FormSection visible={sectionIndex >= 3}>
                <SuccessPanel />
              </FormSection>
            </Sections>
            {sectionIndex <= 2 && (
              <OrderSection>
                {(sectionIndex === 0 || sectionIndex === 2) && (
                  <DueTodayText>Due today <DueTodayPrice>$500 NZD</DueTodayPrice></DueTodayText>
                )}
                {sectionIndex === 2 && (
                  <RefundText>Non-refundable. Except if Halter cannot provide services after farm assessment or Farmer does not want to proceed after receiving install quote.</RefundText>
                )}
                <OrderButton
                  href="#"
                  onClick={async e => {
                    e.preventDefault();
                    
                    if (sectionIndex === 1) {
                      const errors = await validateForm();

                      setTouched({ fullName: true, emailAddress: true, phoneNumber: true, region: true, farmAddress: true, postCode: true, companyName: true, herdSize: true })

                      if (!Object.values(errors).some(x => x.length > 0)) setSectionIndex(c => c + 1);
                    } else if (sectionIndex === 2) {
                      handleSubmit();
                    } else {
                      setSectionIndex(c => c + 1);
                    }
                  }}
                >
                  {isSubmitting && <Spinner src={spinner} />}
                  {getButtonText(sectionIndex, isSubmitting)}
                </OrderButton>
                {sectionIndex === 2 && <OrderTerms>By clicking "Pay" I agree to the <OrderLink href={values.region === 'Waikato' ? './terms-waikato.pdf' : './terms-nz.pdf'} target="_blank">Halter Terms</OrderLink>.</OrderTerms>}
              </OrderSection>
            )}
          </Wrapper>
        )}
      </Formik>
      <Modal
        visible={unknownErrorVisible}
        onDismiss={() => setUnknownErrorVisible(false)}
        title="Error occurred"
        description="An unknown error has occurred. Please try again shortly."
      />
    </>
  );
}

export default injectStripe(Sidebar);
