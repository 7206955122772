/* eslint-disable no-sequences */
import React, { useState } from 'react';
import styled from 'styled-components';

import manage from '../assets/big-images/manage.png';
import monitor from '../assets/big-images/monitor.png';
import shift from '../assets/big-images/shift.png';

import { ReactComponent as Arrow } from '../assets/arrow.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { device } from '../constants/media';

const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;

  overflow: scroll;
`;

const Wrapper = styled.div`
  padding: 160px 24px 80px 24px;
  background-color: #161616;
  position: relative;
  margin-top: -120px;
`;

const Display = styled.div`
  position: relative;
  z-index: 1;
  left: 0; right: 0;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media ${device.laptop} {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const DisplayWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: calc(90vh - 400px);
`;

const BigImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 1s;
`;

const BigImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const SliderWrapper = styled.div`
  overflow: hidden;
`;

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SliderItems = styled.div`
  position: relative;
  display: flex;
  transform: translateX(${props => `${-1 * props.activeIndex * 100}%`});
  transition: transform 1s;
  width: 100%;
  height: 220px;

  @media ${device.laptop} {
    height: 300px;
  }
`;

const SliderItem = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;

  &:nth-of-type(1) { transform: translateX(0%); }
  &:nth-of-type(2) { transform: translateX(100%); }
  &:nth-of-type(3) { transform: translateX(200%); }
`;

const SliderItemContent = styled.div`
  width: 100%;
  max-width: 620px;

  @media ${device.laptop} {
    text-align: center;
  }
`;

const SliderItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media ${device.laptop} {
    justify-content: center;
    text-align: center;
  }
`;

const SliderLeftArrow = styled(Arrow)`
  display: none;
  cursor: pointer;
  margin-bottom: -10px;

  &:hover .inner-icon { fill: #2BB47A }

  @media ${device.laptop} {
    display: flex;
    margin-right: 60px;
  }
`;

const SliderRightArrow = styled(SliderLeftArrow)`
  display: flex;
  transform: rotate(180deg);
  margin-left: 20px;

  @media ${device.laptop} {
    margin-left: 60px;
    margin-right: 0;
  }
`;

const SliderItemTitle = styled.p`
  font-weight: bold;
  color: #ffffff;
  font-size: 3rem;
  letter-spacing: 0.15rem;
  margin-top: 10px;
  margin-bottom: 0;

  @media ${device.laptop} {
    font-size: 7rem;
    margin-top: 0;
  }
`;

const SliderItemText = styled.p`
  color: #6F6E6E;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.35rem;
`;

const OrderButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #28875F -35.71%, #2BB97D 100%);
  border-radius: 4px;
  font-weight: bold;
  color: #ffffff;
  padding: 18px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  margin: 24px 0 -36px 0;

  @media ${device.laptop} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  display: flex; 
  justify-content: center;
  padding: 32px 0;
  margin-bottom: 24px;
  border-bottom: 1px solid #2D2D2D;

  @media ${device.laptop} {
    display: none;
  }
`;

const SliderDots = styled.div`
  display: none;
  justify-content: center;

  @media ${device.laptop} {
    display: flex;
  }
`;

const SliderDot = styled.a`
  padding: 4px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#40C484' : '#383838'};
  transition: background-color 1s;
  margin: 0 4px;
`;

const Content = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Main>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <div style={{ flex: 1 }} />
      <Display>
        <DisplayWrapper>
          <BigImageWrapper visible={activeIndex >= 0}>
            <BigImage src={manage} alt="" />
          </BigImageWrapper>
          <BigImageWrapper visible={activeIndex >= 1}>
            <BigImage src={monitor} alt="" />
          </BigImageWrapper>
          <BigImageWrapper visible={activeIndex >= 2}>
            <BigImage src={shift} alt="" />
          </BigImageWrapper>
        </DisplayWrapper>
      </Display>
      <Wrapper>
        <SliderWrapper>
          <Slider>
            <SliderItems activeIndex={activeIndex}>
              <SliderItem>
                <SliderItemContent>
                  <SliderItemTitleWrapper>
                  <SliderLeftArrow onClick={() => setActiveIndex(2)} />
                    <SliderItemTitle>Manage</SliderItemTitle>
                    <SliderRightArrow onClick={() => setActiveIndex(1)} />
                  </SliderItemTitleWrapper>
                  <SliderItemText>Use the Halter app to set optimised virtual break fences to manage your pasture simply and precisely. Our break creation tool is highly flexible and adaptable giving you the ability to edit and adjust breaks on the go.</SliderItemText>
                </SliderItemContent>
              </SliderItem>
              <SliderItem>
                <SliderItemContent>
                  <SliderItemTitleWrapper>
                    <SliderLeftArrow onClick={() => setActiveIndex(0)} />
                    <SliderItemTitle>Monitor</SliderItemTitle>
                    <SliderRightArrow onClick={() => setActiveIndex(2)} />
                  </SliderItemTitleWrapper>
                  <SliderItemText>The ultimate birds eye view of your farm. Data collected from collar sensors are used to monitor welfare, enabling Halter to predict and alert you through the app when a cow is lame or sick, and when a cow is on heat.</SliderItemText>
                </SliderItemContent>
              </SliderItem>
              <SliderItem>
                <SliderItemContent>
                  <SliderItemTitleWrapper>
                    <SliderLeftArrow onClick={() => setActiveIndex(1)} />
                    <SliderItemTitle>Shift</SliderItemTitle>
                    <SliderRightArrow onClick={() => setActiveIndex(0)} />
                  </SliderItemTitleWrapper>
                  <SliderItemText>Use the Halter app to shift your herd to the milk shed and back or between paddocks. Save time by setting schedules for routine movements instead of manually moving your cows. Use our live map view to capture full visibility of your farm and know that your cows are in the correct zone at all times.</SliderItemText>
                </SliderItemContent>
              </SliderItem>
            </SliderItems>
            <SliderDots>
              <SliderDot href="#" onClick={e => (e.preventDefault(), setActiveIndex(0))}><Dot active={activeIndex === 0} /></SliderDot>
              <SliderDot href="#" onClick={e => (e.preventDefault(), setActiveIndex(1))}><Dot active={activeIndex === 1} /></SliderDot>
              <SliderDot href="#" onClick={e => (e.preventDefault(), setActiveIndex(2))}><Dot active={activeIndex === 2} /></SliderDot>
            </SliderDots>
          </Slider>
        </SliderWrapper>
        <OrderButton href="#sidebar">Prepay</OrderButton>
      </Wrapper>
    </Main>
  );
}

export default Content;