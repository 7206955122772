/* eslint-disable no-sequences */
import React from 'react';
import styled from 'styled-components';
import HeatPanel from './heat-panel';

import { ReactComponent as MapIcon } from '../assets/map.icon.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Paragraph = styled.p`
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #9E9E9E;
  margin: 0;
`;

const Divider = styled.div`
  margin: 22px 0;
  border-bottom: 1px solid #2D2D2D;
`;

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
  letter-spacing: 0.05em;

  color: #FFFFFF;

  margin: 0;
  padding-left: 8px;
`;

const Link = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover { text-decoration: underline; }
`;

const HeatPanelWrapper = styled.div`
  padding-bottom: 16px;
`;

const InfoPanel = () => (
  <Wrapper>
    <HeatPanelWrapper>
      <HeatPanel />
    </HeatPanelWrapper>
  </Wrapper>
);

export default InfoPanel;
