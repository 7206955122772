import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.p`
  color: ${props => props.failed ? '#C82020' : '#ffffff'};
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.failed ? '#C82020' : '#3D3D3D'};
  background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), #1F1F1F;
  color: #9C9C9C;
  padding: 16px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: 12px;
`;

const Select = ({ label, textarea = false, value, ...rest }) => {
  const [field, meta] = useField({ value, ...rest });

  const failed = meta.touched && meta.error != null;

  return (
    <Wrapper>
      <Label failed={failed}>{label}</Label>
      <StyledSelect value={value} failed={failed} {...field} {...rest} />
    </Wrapper>
  );
}

export default Select;
