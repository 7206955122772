import React from 'react';
import styled from 'styled-components';
import { Elements } from 'react-stripe-elements';

import { device } from '../constants/media';

import Content from '../components/content';
import Sidebar from '../components/sidebar';

const Wrapper = styled.div`
  display: flex;
  background-color: #000;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
`;

const MainScreen = () => (
  <Wrapper>
    <Content />
    <Elements>
      <Sidebar />
    </Elements>
  </Wrapper>
);

export default MainScreen;
